// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

let envType: string;
let recaptchaKey: string;
let isAgent = false;
let production = false;
const isThankYouPage = (window.location.href.includes('/thank-you'));

if (window.location.href.includes('/agent')) {
  isAgent = true;
}

if (window.location.href.includes('localhost')) {
  envType = 'local';
} else if (window.location.href.includes('applylu-dev')) {
  envType = 'dev';
} else if (
  window.location.href.includes('applylu-test') || window.location.href.includes('applylu-staging')
) {
  envType = 'test';
} else if (
  window.location.href.includes('applylu-pprd')
) {
  envType = 'pprd';
} else if (
  window.location.href.includes('applylu.apps.')
  || window.location.href.includes('apply-beta.liberty.edu')
  || window.location.href.includes('apply.liberty.edu')
  ) {
  production = true;
  envType = 'prod';
} else {
  envType = 'test';
}

let azureClientId: string;
let azureTenantId: string;
let apiClientId: string;

if (envType === 'prod' || envType === 'pprd') {
  azureClientId = '3fba02c1-97fb-4e37-a626-ce07865a8b87';
  azureTenantId = 'baf8218e-b302-4465-a993-4a39c97251b2';
  apiClientId = 'f484067c-a516-48ae-9e7b-d0ba31dc0b3c';
  recaptchaKey = '6LcvJaUpAAAAACSv07_XURLGDndzRifF4qmPaQsF';
} else {
  azureClientId = '4934be4e-57f3-4086-8feb-dddf3b2cf242';
  azureTenantId = '567757b6-d576-47f2-b515-0313b21ee8ee';
  apiClientId = '8615d988-05ec-471d-9424-a7fff8fa09ed';
  recaptchaKey = '6LcXA2gpAAAAAGM8raMHHJrcZQ_Ogq0sN0poA6kQ';
}


export const environment = {
  production,
  azureClientId,
  azureTenantId,
  isAgent,
  isThankYouPage,
  envType,
  recaptchaKey,
  api: `${location.protocol}//${window.location.host}/rest`,
  scope: `api://${apiClientId}/Read`,
  url: `${location.protocol}//${window.location.host}`
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.


// adding the testers and devs to the AD group or AD role on LBTS which is the test AD directory, vs Azure for prod
