<input type="file" class="input" (change)="onFileSelected($event)" #fileUpload [accept]="approvedFileTypesName" >

<ul class="list mt-3">
    <li class="attachment-item" *ngFor="let file of fileList">
        <p class="mr-3">{{ file.file.name }}</p>
        <div *ngIf="!isEssayUpload && !hideDropdown" class="select-input-wrap">
          <label class="mr-4">
            <select (change)="changeFileType(file.file.name, $event.target.value)" required>
              <option value="HighSchoolTranscript" [selected]="level === 'Undergraduate'">High School Transcript</option>
              <option value="CollegeTranscript" [selected]="level === 'Graduate'">College Transcript</option>
            </select>
          </label>
          <app-icon class="icon" style="pointer-events: none" [icon]="'dropdown-arrow'" aria-label="Dropdown Arrow icon"></app-icon>
        </div>
        <a class="close" (click)="removeFile(file.file.name)" (keyup.enter)="removeFile(file.file.name)" tabindex="0" [attr.aria-label]="'Click to remove attachment: '+file">
        </a>
    </li>
</ul>

<div class="">
    <div class="file-errors" role="alert">
        <span class="error" *ngIf="errorFileType">Cannot upload this file: wrong type</span>
        <span class="error" *ngIf="errorFileNameLength">Cannot upload this file: file name too long (100 character max)</span>
        <span class="error" *ngIf="errorFileSize">Cannot upload this file: file size too large (5mb max size)</span>
        <span class="error" *ngIf="errorFileDuplicateName">Cannot upload this file: file already uploaded</span>
    </div>
    <div class="file row">
        <div class="file col-auto mb-2">

            <button type="button" tabindex="0" class="button button-ghost" (click)="fileUpload.click()" [attr.aria-label]="'Attach '+uploadType+'s'" aria-describedby="approved-types">
                + Attach {{uploadType}}
            </button>
            <p class="approvedTypes" id="approved-types">Approved file types: {{approvedFileTypesName}}</p>
        </div>

        <div *ngIf="showSendButton" class="file col-auto mb-2">
            <button
              [ngClass]="{'dont-show': showLoadingAnimation}"
              type="button"
              tabindex="0"
              [attr.disabled]="showLoadingAnimation ? 'disabled' : null"
              (click)="filesSubmitted()"
              [attr.aria-label]="'Send ' + uploadType"
            >
                Send {{uploadType}}
              <app-icon *ngIf="!showLoadingAnimation" class="continueArrow" aria-hidden="true" [icon]="eIconTypes.SideWhiteArrow"></app-icon>
              <div *ngIf="showLoadingAnimation" class="spinner-container">
                  <div class="spinner">
                      <div></div>
                  </div>
              </div>
            </button>
        </div>
    </div>
</div>
