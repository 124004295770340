import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {firstValueFrom} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor(
    private http: HttpClient,
    private router: Router
  ) {
  }

  async getToken() {
    try {
      await firstValueFrom(this.http.get<void>('/rest/token'));
    } catch (error) {
      await this.router.navigateByUrl('/maintenance');
    }
  }

}
