import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FileUploadComponent} from 'src/app/form/input/generic/file-upload/file-upload.component';
import {EmailMessage, EmailType} from 'src/app/shared/model/email-message.model';
import {ApplicationService} from 'src/app/shared/service/application.service';
import {EmailService} from 'src/app/shared/service/email.service';
import {ProgramService} from '../../shared/service/program.service';
import {ApplicationDocument} from '../../shared/model/application-document';

@Component({
  selector: 'app-essay-upload',
  templateUrl: './essay-upload.component.html',
  styleUrls: ['./essay-upload.component.scss']
})
export class EssayUploadComponent implements OnInit {
  @Input()
  replyToAddress = '';

  @Input()
  fullName: string;

  @ViewChild(FileUploadComponent) fileUploadComponent: FileUploadComponent;

  toAddress = EmailMessage.ADMISSION_DOCS_RES;
  approvedFileTypes = ['application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword'];
  approvedFileTypesNames = [' .docx', ' .doc'];

  sentToAPI = false;
  failedSendToAPI = false;
  fileListLength = 0;

  constructor(
    private emailService: EmailService,
    private applicationService: ApplicationService,
    private programService: ProgramService,
  ) { }

  ngOnInit(): void {
  }

  sendEssays(fileList: ApplicationDocument[]) {
    const webId = this.applicationService.webId;
    const luid = this.applicationService.generateLuidresponse.luid;
    this.fileListLength = fileList.length;
    const theProgram = this.programService.getProgramByProgramCode(this.applicationService?.application?.majorFoses[0]?.programCode);

    this.fileUploadComponent.showLoadingAnimation = true;
    const messageBody = 'Essay: ' + this.fullName + ((webId && webId.length > 0) ? ', ' + webId : '') + '\nEmail: ' + this.replyToAddress.toUpperCase();
    const messageSubject = 'Essay: Residential Applicant';

    const emailMessage: EmailMessage = new EmailMessage(EmailMessage.NO_REPLY, this.toAddress,
      this.replyToAddress, messageSubject, messageBody, EmailType.ESSAY);

    this.emailService.sendAttachmentToEtrieve(webId, fileList, theProgram, emailMessage, luid).subscribe(() => {
      this.fileUploadComponent.showLoadingAnimation = false;
      this.sentToAPI = true;
    }, () => {
      this.fileUploadComponent.showLoadingAnimation = false;
      this.failedSendToAPI = true;
    }
    );
  }



}
