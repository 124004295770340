import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import { CookieService } from '../service/cookie.service';
import { IncompleteApp } from '../model/applyLUApiObjects/incomplete-app.model';
import moment from 'moment/moment';
import {apm} from "@elastic/apm-rum";

@Injectable({
  providedIn: 'root'
})
export class AppIdService {

  public appJson: { [key: string]: any };
  public resumeLink = false;
  public ipUpdated = new Subject();
  private readonly APP_ID_PREFIX = 'ALU_';
  private appId: string;
  private timeStamp: string;
  private createdDate: Date;
  private ipAddress: string;
  private applicationResumeKey: string;
  private appIdSet = new BehaviorSubject('');

  constructor(private http: HttpClient, private cookieService: CookieService) {
  }

  private config$: Observable<any>;

  getAppIdSetSub(): Observable<string> {
    return this.appIdSet.asObservable();
  }

  appIdSetSubNext(appId: string) {
    this.appIdSet.next(appId);
  }

  load(): Observable<any> {
    this.cookieService.startNewAppSubject.subscribe(val => {
      if (val && !this.resumeLink && this.appId && this.appId !== 'Unavailable' && this.appId !== 'undefined') {
        this.cookieService.addCookie(this.cookieService.cookieEnum.APP_ID, this.appId, 30);
        this.cookieService.addCookie(this.cookieService.cookieEnum.RESUME_KEY, this.applicationResumeKey, 30);
      }
    });
    if (!this.config$) {
      this.config$ = this.http
        .post<{ id: string, createdTimestamp: number }>('/rest/applications', {})
        .pipe(
          shareReplay(1),
          map((response: { id: string, appJson: string, createdTimestamp: number, resumeKey: string, ipAddress: string }) => {
            this.timeStamp = response.createdTimestamp.toString();
            this.setAppId(response.id);
            if (response.appJson) {
              this.appJson = JSON.parse(response.appJson);
            }

            if (response.ipAddress) {
              this.setIpAddr(response.ipAddress);
            }
            this.applicationResumeKey = response.resumeKey;

            if (
              !this.cookieService.checkCookie(this.cookieService.cookieEnum.APP_ID)
              || this.cookieService.getCookie(this.cookieService.cookieEnum.APP_ID) === 'undefined'
              || this.cookieService.getCookie(this.cookieService.cookieEnum.APP_ID) === 'Unavailable'
              || this.cookieService.getCookie(this.cookieService.cookieEnum.APP_ID) !== this.getAppId()) {
              apm.captureError(`An Error has occurred while trying to check the AppID Cookie: AppID: ${this.getAppId()}, APP_ID Cookie: ${this.cookieService.getCookie(this.cookieService.cookieEnum.APP_ID)}`)
              this.cookieService.deleteCookie(this.cookieService.cookieEnum.APP_ID);
              this.cookieService.startNewAppSubject.next(true);
            }
            return this.getPrefixedAppId();
          }), catchError((error) => {
            const labels = {
              appId: this.getAppId(),
              cookie_appid : this.cookieService.getCookie(this.cookieService.cookieEnum.APP_ID),
              exception_message: error.message.toString() || "Unknown error",
              exception_name: error.name || "Unknown",
              exception_trace: error.stack,
              timestamp: new Date().toLocaleString("en-US", { timeZone: "America/New_York" })
            };
            apm.addLabels(labels)
            apm.captureError(`An Error has occurred while trying to create application: AppID: ${this.getAppId()}, Check Meta data for more detail.`)
            const timeStamp = new Date().getTime();
            // const timeZoneOffset = today.format('Z'); // Optionally capture the time zone offset if needed
            this.ipAddress = '127.0.0.1';
            this.timeStamp = timeStamp.toString();
            return this.appId = 'Unavailable';
          })
        );
    }
    else {
      apm.captureError(`Config$ is null or undefined. AppID: ${this.appId}`)
    }
    return this.config$;
  }

  public setProperties(incompleteApp: IncompleteApp) {
    if (incompleteApp.createdTimestamp) {
      this.timeStamp = incompleteApp.createdTimestamp.toString();
    }
    this.appId = incompleteApp.id.toString();
    if (incompleteApp.ipAddress) {
      this.setIpAddr(incompleteApp.ipAddress);
    } else if (!this.ipAddress) {
      this.setIpAddr('Unavailable');
    }
    if (incompleteApp.resumeKey) {
      this.applicationResumeKey = incompleteApp.resumeKey;
    }

  }

  public getPrefixedAppId(): string {
    return this.getAppId() ? this.APP_ID_PREFIX + this.getAppId() : this.getAppId();
  }

  public getAppId(): string {
    return this.appId;
  }

  public setAppId(id: string): void {
    this.appId = id;
    this.appIdSetSubNext(this.appId);
  }

  public getCreatedTimestamp(): Date {
    const timestamp = parseInt(this.timeStamp, 10);
    const date = new Date(timestamp);
    const convertedTimestamp = new Date(date.toLocaleString('en-US', { timeZone: 'America/New_York'}));
    this.createdDate = new Date(convertedTimestamp);
    return this.createdDate;
  }

  public getIpAddr(): string {
    // On load, this is pulled from the createApplication endpoint
    return this.ipAddress;
  }

  public setIpAddr(ip: string): void {
    this.ipAddress = ip;
    this.ipUpdated.next(ip);
  }

  public getResumeKey(): string {
    return this.applicationResumeKey;
  }
}

