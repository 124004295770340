import {ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NavigationService} from 'src/app/shared/service/navigation.service';
import {ProgramService} from '../../shared/service/program.service';
import {ApplicationService} from '../../shared/service/application.service';
import {FormService} from '../../shared/service/form.service';
import {ActivatedRoute} from '@angular/router';
import {SchoolService} from '../../shared/service/school.service';
import {AppIdService} from 'src/app/shared/provider/app-id.service';

import {PopupService} from 'src/app/shared/service/popup.service';
import {FormComponent} from '../form.component';

import {RouterService} from 'src/app/shared/service/router.service';
import {CookieService} from 'src/app/shared/service/cookie.service';
import {StudentService} from 'src/app/shared/service/student.service';
import {Program} from 'src/app/shared/model/program.model';
import {DomService} from 'src/app/shared/service/dom.service';
import {takeUntil} from 'rxjs/operators';
import {EFormType} from 'src/app/shared/model/app-form-builder.model';
import {NationService} from 'src/app/shared/service/nation.service';
import {StateService} from 'src/app/shared/service/state.service';
import {ModalService} from '../../shared/service/modal.service';

const beforeUnloadHandler = (e) => {
  e.preventDefault();
  e.returnValue = '';
};

@Component({
  selector: 'app-agent-form',
  templateUrl: './agent-form.component.html'
})
export class AgentFormComponent extends FormComponent implements OnInit, OnDestroy {

  selectedProgram: Program = new Program();

  constructor(
    public cdr: ChangeDetectorRef,
    public navigationService: NavigationService,
    public programService: ProgramService,
    public applicationService: ApplicationService,
    public route: ActivatedRoute,
    public formService: FormService,
    public schoolService: SchoolService,
    public appIdService: AppIdService,
    public popupService: PopupService,
    public routerService: RouterService,
    public cookieService: CookieService,
    public nationService: NationService,
    public stateService: StateService,
    public studentService: StudentService,
    public el: ElementRef,
    public domService: DomService,
    public modalService: ModalService
  ) {
    super(
      cdr,
      navigationService,
      programService,
      applicationService,
      route,
      formService,
      schoolService,
      appIdService,
      popupService,
      routerService,
      cookieService,
      nationService,
      stateService,
      studentService,
      el,
      domService,
      modalService
    );

    // build our reactive App Form structure
    this.applicationForm = this.appFormBuilder.buildForm(EFormType.agent);

  }

  ngOnInit() {

    super.ngOnInit();

    this.appIdService.resumeLink = false;
    this.appIdService.load().subscribe();

    this.navigationService.getEmitActiveSectionSub().pipe(takeUntil(this.endSubscriptions)).subscribe(activeSection => {
      this.activeSectionName = activeSection.name;
    });

    // Setup subscriptions for each section for agents. This accomplishes validation when forms are touched.
    this.navigationService.setupSectionValidation(false, this.applicationForm, this.endSubscriptions);
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    // Show a window dialogue when the AGENT user tries to close the tab.
    window.addEventListener('beforeunload', beforeUnloadHandler);
  }

  ngOnDestroy() {
    window.removeEventListener('beforeunload', beforeUnloadHandler);
    super.ngOnDestroy();
  }

  public updateEmailAddress(data: string) {
    this.applicationForm.get('personalInformation').get('email').setValue(data);
    this.applicationForm.get('agentQuestions').get('email_dup_checker').setValue(data);
    this.cdr.detectChanges();
  }

  public async submitApp() {
    this.formService.submitted = true;
    this.applicationForm.markAllAsTouched();
    this.applicationForm.updateValueAndValidity();

    this.navigationService.validateAllSections();

    this.formErrors = this.formService.gatherFormErrors(this.applicationForm);

    if (this.formErrors.length > 0) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });

    } else {
      // Allow applicant to pay the Enrollment Deposit
        const theProgram = this.programService.getProgramByProgramCode(this.applicationService?.application?.majorFoses[0]?.programCode);
        await this.applicationService.submitApplication(this.applicationForm.getRawValue());
        this.routerService.navigate('/thank-you/.', theProgram, true);
      }
    }
}
