/// <reference types="@types/google.maps" />

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { FormService } from 'src/app/shared/service/form.service';
import { NationService } from 'src/app/shared/service/nation.service';
import { Nation } from 'src/app/shared/model/nation.model';
import { State } from 'src/app/shared/model/state.model';
import { Address } from 'src/app/shared/model/application/address.model';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Program } from 'src/app/shared/model/program.model';
import { NavigationService } from 'src/app/shared/service/navigation.service';
import { StateService } from 'src/app/shared/service/state.service';
import { ProgramService } from 'src/app/shared/service/program.service';
import { PlacesAutocompleteComponent } from './places-autocomplete/places-autocomplete.component';
import { StateSearchComponent } from '../../generic/state-search/state-search.component';
import {
  SearchableSelectDropDownComponent
} from '../../generic/searchable-select-drop-down/searchable-select-drop-down.component';
import { CookieService } from '../../../../shared/service/cookie.service';


@Component({
  selector: 'app-full-address',
  templateUrl: './full-address.component.html',
  styleUrls: ['./full-address.component.scss']
})
export class FullAddressComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() formGroup: FormGroup<any> = new FormGroup({});
  @Input() showResideRadio = true;
  @Input() activateAutoComplete = true;

  @ViewChild(PlacesAutocompleteComponent) autoCompleteComponent: PlacesAutocompleteComponent;
  @ViewChild('nationSelect') nationSearchDropdown: SearchableSelectDropDownComponent<Nation>;
  @ViewChild(StateSearchComponent) stateSearchDropdown: StateSearchComponent;

  currentProgram = new Program();
  allCountries = new Array<Nation>();

  stateSearchCountry = 'US';

  autoCompleteMissingFields = false;

  endSubscriptions = new Subject<void>();

  zipCode: string;

  noZipCountries = ['Angola', 'Antigua and Barbuda', 'Aruba', 'Bahamas', 'Belize', 'Benin', 'Botswana', 'Burkina Faso', 'Burundi', 'Cameroon', 'Central African Republic', 'Comoros', 'Congo', 'Congo, Democratic Republic', 'Congo, Democratic Republic of', 'Cook Islands', 'Côte d\'Ivoire', 'Cote d’Ivoire', 'Cote D\'Ivoire', 'Djibouti', 'Dominica', 'East Timor', 'Equatorial Guinea', 'Eritrea', 'Fiji', 'French Southern Territories', 'Gambia', 'Ghana', 'Grenada', 'Guinea', 'Guyana', 'Hong Kong', 'Jamaica', 'Kenya', 'Macau', 'Kiribati', 'North Korea', 'Macau', 'Macao', 'Malawi', 'Mali', 'Mauritania', 'Mauritius', 'Montserrat', 'Nauru', 'Netherlands Antilles', 'Niue', 'Panama', 'Qatar', 'Rwanda', 'Saint Kitts and Nevis', 'Saint Lucia', 'Sao Tome and Principe', 'Saudi Arabia', 'Seychelles', 'Sierra Leone', 'Solomon Islands', 'Somalia', 'South Africa', 'Suriname', 'Syria', 'Syrian Arab Republic', 'Tanzania', 'Tanzania, United Republic of', 'Tokelau', 'Tonga', 'Trinidad and Tobago', 'Tuvalu', 'Uganda', 'United Arab Emirates', 'Vanuatu', 'Yemen', 'Zimbabwe',
  ];

  showInternationalDisclosure = false;
  internationalDisclosurePrograms = [
    'DPMH-DNP-D', // doctorate > Psychiatric Mental Health Nurse Practitioner
    'DFNP-DNP-D', 'CTMH-CTG-D'
  ];

  constructor(
    public formService: FormService,
    public nationService: NationService,
    private el: ElementRef,
    private navigationService: NavigationService,
    private stateService: StateService,
    private programService: ProgramService,
    public cookieService: CookieService,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {

    this.allCountries = this.nationService.returnNations();

    this.nationService.nationsSubject.pipe(takeUntil(this.endSubscriptions)).subscribe(data => {
      this.allCountries = data;
      this.cdr.detectChanges();
    });

    this.formGroup.get('personal_info_country').valueChanges.pipe(takeUntil(this.endSubscriptions)).subscribe(countryName => {
      this.formGroup.get('personal_info_country').setValidators([Validators.required]);
      if (countryName) {
        this.maybeAddMaxLength(countryName.trim());
      } else {
        this.formGroup.get('personal_info_country_code').setValue('');
      }

    });

    // this should only fire when browser (like Chrome) Address autofill happens
    this.formGroup.get('shipping-state').valueChanges
      .pipe(debounceTime(1000), takeUntil(this.endSubscriptions))
      .subscribe(val => {
        this.selectStateByVal(val);
      });

    this.formGroup.valueChanges.pipe(takeUntil(this.endSubscriptions)).subscribe(() => {
      this.maybeDisplayInternationalDisclosure();

      this.setApplicationAddress();
    });

    this.formService.prefillForm.pipe(takeUntil(this.endSubscriptions)).subscribe(prefillObj => {
      const passedFormGroup = prefillObj.formData;
      const addressFields = passedFormGroup['personalInformation']['address_fields'];
      const prefillAddress = new Address();

      if (addressFields.us_resident_radio) {
        this.formGroup.get('us_resident_radio').setValue(addressFields.us_resident_radio);
      }
      if (addressFields.personal_info_country) {
        this.formGroup.get('personal_info_country').setValue(addressFields.personal_info_country);
      }
      if (addressFields.personal_info_country_code) {
        this.formGroup.get('personal_info_country_code').setValue(addressFields.personal_info_country_code);
      }

      prefillAddress.city = addressFields.city;
      prefillAddress.nationCode = addressFields.personal_info_country_code;
      prefillAddress.address1 = addressFields.street_address;
      prefillAddress.zip = addressFields.zip_code;
      prefillAddress.state = addressFields.personal_info_state ? addressFields.personal_info_state : addressFields.international_state;

      this.prefillByAddress(prefillAddress);

    });

  }

  ngAfterViewInit(): void {
    this.programService.getProgramSub().pipe(takeUntil(this.endSubscriptions)).subscribe(program => {
      this.currentProgram = program;

      if (this.currentProgram.programCode && this.formGroup.get('us_resident_radio') && this.formGroup.get('us_resident_radio').value !== '') {
        this.updateUsResidencySelection(this.formGroup.get('us_resident_radio').value);
      }
    });
  }

  ngOnDestroy() {
    this.endSubscriptions.next();
    this.endSubscriptions.complete();
  }

  setApplicationAddress() {

    const address = new Address();

    address.address1 = this.formGroup.get('street_address').value;
    address.city = this.formGroup.get('city').value;
    address.state = this.formGroup.get('personal_info_state').value;
    address.zip = this.formGroup.get('zip_code').value;

    if (this.formGroup.get('international_state').value) {
      address.address3 = this.formGroup.get('international_state').value;
    }
    address.county = this.formGroup.get('personal_info_country').value;
    address.nationCode = this.formGroup.get('personal_info_country_code').value;
    address.type = 'AP';
    this.formService.setApplicationAddress(address);
  }

  selectStateByVal(stateVal: string) {
    let findState: State;
    if (stateVal) {
      findState = this.stateService.getStateByCode(stateVal);
      if (!findState) {
        findState = this.stateService.getStateByName(stateVal);
      }
    }

    if (findState) {

      if (this.formGroup.get('personal_info_country_code').value === 'US') {
        this.formGroup.get('personal_info_state').setValue(findState.name ? findState.name : findState);
      } else {
        this.formGroup.get('international_state').setValue(findState.name ? findState.name : findState);
      }

      if (this.stateSearchDropdown) {
        this.stateSearchDropdown.searchableSelectDropDownComponent.selectItem(findState);
      }

    }
  }

  prefillByAddress(address: Address) {
    let findCountry: Nation;

    if (address.nationCode) {
      findCountry = this.nationService.findByCode(address.nationCode);
      if (!findCountry) {
        findCountry = this.nationService.findByName(address.nationCode);
      }
    }

    this.formGroup.get('street_address').setValue(address.address1);
    if (this.autoCompleteComponent?.autocompleteInput?.nativeElement) {
      this.autoCompleteComponent.autocompleteInput.nativeElement.value = address.address1;
    }

    this.formGroup.get('city').setValue(address.city);

    if (address.nationCode === 'US') {
      this.formGroup.get('us_resident_radio').setValue('Yes');
    } else {
      this.formGroup.get('us_resident_radio').setValue('No');
    }

    this.selectStateByVal(address.state);

    // this wasn't being updated if there was already a zip. Moving this line down here as the last thing helped, not sure why
    this.formGroup.get('zip_code').setValue(address.zip);

    if (findCountry) {
      this.nationSearchDropdown.selectItem(findCountry, false);
      this.formGroup.get('personal_info_country').setValue(findCountry.name);
      this.formGroup.get('personal_info_country_code').setValue(findCountry.code);
    }


  }

  // stuff for Google autocomplete
  public onAutoCompleteBlur($event: FocusEvent) {
    const inputTarget = $event.target as HTMLInputElement;
    this.formGroup.get('street_address').setValue(inputTarget.value.trim());
  }

  public onGooglePlacesAutocomplete(place: google.maps.places.PlaceResult) {
    this.formGroup.get('usedGooglePlacesAPI').setValue('Yes');
    const addressArray = place.address_components;

    const prefillAddress = new Address();
    let streetNum: string;
    let streetName: string;

    if (addressArray.length > 0) {
      addressArray.forEach(part => {

        for (const item of part.types) {

          if (item === 'street_number') {
            streetNum = part.long_name;
          }

          if (item === 'route') {
            streetName = part.long_name;
          }

          if (item === 'locality' || item === 'city') {
            prefillAddress.city = part.long_name;
          }

          if (item === 'administrative_area_level_1' || item === 'state') {
            prefillAddress.state = part.long_name;
          }

          if (item === 'country') {
            prefillAddress.nationCode = part.short_name; // should always be US
          }

          if (item === 'postal_code') {
            prefillAddress.zip = part.short_name;
          }

        }
      });
    }

    if (streetNum === undefined || streetNum === null) {
      streetNum = '';
    }
    if (streetName === undefined || streetName === null) {
      streetName = '';
    }
    prefillAddress.address1 = `${streetNum} ${streetName}`.trim();

    if (this.autoCompleteComponent) { this.autoCompleteComponent.autocompleteInput.nativeElement.focus(); }

    this.prefillByAddress(prefillAddress);

  }

  updateCurrentPersonalInfoCountry(nation?: Nation) {
    if (nation && nation.code) {
      this.formGroup.get('personal_info_country_code').setValue(nation.code);
      if (nation.code === 'US' || nation.code === 'CA' || nation.code === 'AU') {

        this.formGroup.get('international_state').setValue('');
        this.formGroup.get('personal_info_state').enable();
        this.formGroup.get('international_state').disable();
        this.formGroup.get('international_state').clearValidators();

        if (nation.code === 'US') {
          this.formGroup.get('us_resident_radio').setValue('Yes', { emitEvent: false });
          this.formService.residesInUs.next(true);
        } else {
          this.formGroup.get('us_resident_radio').setValue('No', { emitEvent: false });
          this.formService.residesInUs.next(false);
        }

        // currently has state name, need to get state code by name
        const theState = this.stateService.getStateByName(this.formGroup.get('personal_info_state').value);
        // primarily passed down to the state search component
        this.stateSearchCountry = nation.code;

        if (theState && this.stateSearchDropdown && this.stateSearchDropdown.chosenState && this.stateSearchDropdown.chosenState.code !== theState.code) { // dont bother running if already selected
          this.stateSearchDropdown.searchableSelectDropDownComponent.selectItem(theState);
        }

      } else {
        if (this.stateSearchDropdown) {
          this.stateSearchDropdown.addState(new State());
          this.stateSearchDropdown.searchableSelectDropDownComponent.resetSearch();
        }

        this.formGroup.get('personal_info_state').setValue('');
        this.formGroup.get('personal_info_state').disable();
        this.formGroup.get('international_state').enable();
        this.formGroup.get('international_state').clearValidators();

        // Update the state subject so that when the state clears, programDisclosure gets it and can clear it
        // (on the updatedState pipe line 62 of program-state-disclosure.component.ts):
        this.formService.updatedState.next(new State());

        this.formGroup.get('us_resident_radio').setValue('No', { emitEvent: false });
        this.formService.residesInUs.next(false);
      }

      this.formGroup.get('international_state').updateValueAndValidity();
      // check to see if required validation should be removed from the zip code field
      this.maybeAddMaxLength(nation.name.trim());

    } else {
      this.formGroup.get('personal_info_country_code').setValue('');
      this.formGroup.get('us_resident_radio').setValue('No', { emitEvent: false });

    }

  }

  updateUsResidencySelection(val: string) {
    if (val === 'Yes') {
      this.nationSearchDropdown.itemSearch = 'United States';
      const selectedNation: Nation = { code: 'US', name: 'United States' };
      this.nationSearchDropdown.selectItem(selectedNation, false);
      this.formGroup.get('international_state').disable();
      this.formGroup.get('international_state').setValue('');
      this.formGroup.get('personal_info_state').enable();
      this.formService.residesInUs.next(true);
    } else {
      this.nationSearchDropdown.itemSearch = '';
      if (this.nationSearchDropdown) {
        this.nationSearchDropdown.selectItem(new Nation(), false);
      }
      if (this.stateSearchDropdown) {
        this.stateSearchDropdown.addState(new State());
      }
      this.formGroup.get('personal_info_country').setValue('');
      this.formGroup.get('personal_info_state').disable();
      this.formGroup.get('personal_info_state').setValue('');
      this.formGroup.get('international_state').enable();
      this.formService.residesInUs.next(false);
    }
    this.maybeDisplayInternationalDisclosure();
    this.formGroup.get('international_disclosure').updateValueAndValidity();

    this.setApplicationAddress();

  }


  maybeDisplayInternationalDisclosure() {
    const selectedProgramCode = this.currentProgram.programCode;
    if (
      this.formGroup.get('us_resident_radio')
      && this.formGroup.get('us_resident_radio').value === 'No'
      && this.internationalDisclosurePrograms.includes(selectedProgramCode)
    ) {
      this.formGroup.get('international_disclosure').setValidators([Validators.required]);
      this.navigationService.disableSection('agreeSubmit');
      this.showInternationalDisclosure = true;
    } else {
      this.showInternationalDisclosure = false;
      this.formGroup.get('international_disclosure').clearValidators();
      this.navigationService.enableSection('agreeSubmit');
    }
  }

  maybeAddMaxLength(countryName: string) {
    if (countryName === 'United States') {
      this.formGroup.get('zip_code').setValidators([Validators.required]);
      if (this.zipCode) {
        this.zipCode = this.zipCode.substring(0, 5);
        this.formGroup.get('zip_code').setValue(this.zipCode);
      }
    } else if (this.noZipCountries.includes(countryName)) {
      this.formGroup.get('zip_code').clearValidators();
    } else {
      this.el.nativeElement.querySelector('#international_zip_code').setAttribute('maxlength', '30');
      this.formGroup.get('zip_code').setValidators([Validators.required]);
    }
  }

}
