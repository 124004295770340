import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {EIconType} from '../../../../shared/component/icon/icon.component';
import {ApplicationDocument} from '../../../../shared/model/application-document';
import {ProgramService} from '../../../../shared/service/program.service';
import {ApplicationService} from '../../../../shared/service/application.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {

  @Input() approvedFileTypes: string[] = [];
  @Input() approvedFileTypesName: string[] = [];
  @Input() uploadType = '';
  @Input() isEssayUpload = false;
  @Input() hideDropdown = false;

  @Output() filesEmitted = new EventEmitter<ApplicationDocument[]>();
  fileList: ApplicationDocument[] = [];

  errorFileType = false;
  errorFileNameLength = false;
  errorFileSize = false;
  errorFileDuplicateName = false;
  showLoadingAnimation = false;
  level = '';

  public showSendButton = false;
  public eIconTypes: typeof EIconType = EIconType;

  constructor(
    private applicationService: ApplicationService,
    private programService: ProgramService
  ) { }

  ngOnInit(): void {
  }


  changeFileType(fileName: string, type: string) {
    this.fileList.find(file => file.file.name === fileName).etrieveFileType = type;
  }

  removeFile(fileName: string) {
    this.fileList = this.fileList.filter(file => {
      return file.file.name !== fileName;
    });

    this.maybeShowSendButton();
  }

  maybeShowSendButton() {
    this.showSendButton = this.fileList.length > 0;
  }

  onFileSelected(item) {
    const file: File = item.target.files[0];
    this.errorFileType = false;
    this.errorFileNameLength = false;
    this.errorFileSize = false;
    this.errorFileDuplicateName = false;

    if (!this.approvedFileTypes.includes(file.type)) {
      this.errorFileType = true;

    } else if (file.name.length > 100) {
      this.errorFileNameLength = true;

    } else if (file.size > 5000000) {
      this.errorFileSize = true;

    } else if (this.fileList.filter(f => f.file.name === file.name).length > 0) {
      this.errorFileDuplicateName = true;
    }
    if (!this.errorFileType && !this.errorFileNameLength && !this.errorFileSize && !this.errorFileDuplicateName) {
      const theProgram = this.programService.getProgramByProgramCode(this.applicationService?.application?.majorFoses[0]?.programCode);
      this.level = theProgram.level;
      let defaultType = 'HighSchoolTranscript';
      if (this.isEssayUpload) {
        defaultType = 'AdmissionsEssay';
      } else if (this.level === 'Graduate') {
        defaultType = 'CollegeTranscript';
      }
      this.fileList.push(new ApplicationDocument(file, defaultType));
    }


    this.maybeShowSendButton();
  }

  filesSubmitted() {
    this.filesEmitted.next(this.fileList);

    this.fileList = [];
  }
}
