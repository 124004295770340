import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, of} from 'rxjs';

import {Program} from '../model/program.model';
import {College} from '../model/application/college.model';
import {ELevelCategory, EProgLevel} from '../model/prog-level.model';
import {CampusLevelProgramArray} from '../model/campus-level-program-array.model';
import {retry, switchMap, take, timeout} from 'rxjs/operators';
import {EmailService} from 'src/app/shared/service/email.service';
import {EmailMessage, EmailType} from 'src/app/shared/model/email-message.model';

@Injectable({
  providedIn: 'root'
})

export class ProgramService {

  allPrograms: Program[] = [];
  private LIBERTY_CEEB = '005385';
  private programsByCampusLevel = new CampusLevelProgramArray(); // all programs in campus/level combos as simple arrays
  public pickerPrograms = new CampusLevelProgramArray(); // the final prepared Object to  display in the pickers, has a different structure
  private pickerSelectedSubject = new BehaviorSubject(new Program('')); // this is mostly for the degree-picker-modal to emit the program selected to the degree-picker before the main programSelectedSubject is emitted
  private programSelectedSubject = new BehaviorSubject(new Program(''));

  private programsLoaded = new BehaviorSubject(false);
  private allProgramsLoaded = new BehaviorSubject(false);

  private currentProgram = new Program();
  private currentLevelCategory: ELevelCategory;
  private ONLINE_READMIT_WAPP = 'DA'
  private ONLINE_TRANSFER_WAPP = 'DT'
  private RESIDENT_READMIT_WAPP = 'RR'
  private RESIDENT_TRANSFER_WAPP = 'RT'
  private isNestedProgramsLoaded = false;

  constructor(
    private http: HttpClient,
    private emailService: EmailService
  ) {

    this.currentProgram.programCode = '';

    this.programSelectedSubject.subscribe(program => {
      this.currentProgram = program;
      this.currentLevelCategory = this.getLevelCategoryByProgramCode(program.programCode);
    });
  }

  getAllProgramsLoadedSub(): Observable<boolean> {
    return this.allProgramsLoaded.asObservable();
  }

  setCurrentProgram(program: Program) {
    this.currentProgram = program;
  }

  public getCurrentLevelCategory(): ELevelCategory {
    return this.currentLevelCategory;
  }

  getPickerSelectedSub(): Observable<Program> {
    return this.pickerSelectedSubject.asObservable();
  }

  getProgramSub(): Observable<Program> {
    return this.programSelectedSubject.asObservable();
  }

  pickerSelectedSubNext(program: Program) {
    this.pickerSelectedSubject.next(program);
  }

  programSubNext(program: Program) {
    this.programSelectedSubject.next(program);
  }

  generateEmptyProgram() {
    const returnedProgram = new Program();
    returnedProgram.programCode = '';
    returnedProgram.level = '';
    returnedProgram.levelCode = '';
    returnedProgram.campCode = '';
    return returnedProgram;
  }

  prepareAllProgramsByCampusLevel() {

    const returnedPrograms = new CampusLevelProgramArray();

    this.allPrograms.forEach((thisProgram: Program) => {
      if (!thisProgram.programCode) {
        return;
      }

      const progCampus = thisProgram.campCode;

      let progLevel = thisProgram.degreeLevel;
      if (progLevel === 'Less than Associate') {
        progLevel = 'Associate';
      } else if (progLevel === 'Law Degree') {
        progLevel = 'Doctorate';
      }
      returnedPrograms[progCampus][progLevel].push(thisProgram);

    });

    this.programsByCampusLevel = returnedPrograms;
  }

  returnProgramsByCampusLevel(): CampusLevelProgramArray {
    return this.programsByCampusLevel;
  }

  getProgramByProgramCodeAndName = (programCode: string, programName: string): Program => {
    if (!programCode && !programName) {
      return this.generateEmptyProgram();
    }
    const foundProgram = this.allPrograms.find(program => program.programCode === programCode.toUpperCase()
      && (program.cognate || program.majorGroup) === programName);

    return foundProgram || this.generateEmptyProgram();
  }

  getProgramByProgramCode = (programCode = ''): Program => {
    if (!programCode) {
      return this.generateEmptyProgram();
    }
    const foundProgram = this.allPrograms.find(program => program.programCode === programCode.toUpperCase());
    if (foundProgram) {
      return foundProgram;
    } else {
      return this.generateEmptyProgram();
    }
  }

  getCampusByProgramCode = (programCode = ''): string => {
    if (!programCode) {
      return '';
    }
    const theProgram = this.allPrograms.find(program => program.programCode === programCode.toUpperCase());
    if (theProgram) {
      return theProgram.campCode;
    } else {
      return '';
    }
  }

  getLevelByProgramCode = (programCode: string): EProgLevel => {
    if (!programCode) {
      return null;
    }
    const allProgramsArray: Program[] = Object.values(this.allPrograms);
    const theProgram = allProgramsArray.find(program => program.programCode === programCode.toUpperCase());
    let returnedEnum: EProgLevel;

    if (theProgram) {
      const degreeLevelString = theProgram.degreeLevel;
      returnedEnum = EProgLevel[degreeLevelString];

      if (theProgram.degreeLevel === 'Certificate' && theProgram.level === 'Graduate') {
        returnedEnum = EProgLevel.Master;
      } else if (theProgram.degreeLevel === 'Certificate' && theProgram.level === 'Doctorate') {
        returnedEnum = EProgLevel.Doctorate;
      } else if (degreeLevelString === 'Less than Associate') {
        returnedEnum = EProgLevel.LessthanAssociate;
      } else if (degreeLevelString === 'Law Degree') {
        returnedEnum = EProgLevel.Doctorate;
      }
      if (returnedEnum) {
        return returnedEnum;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  getLevelCategoryByProgramCode = (programCode: string): ELevelCategory => {
    if (!programCode) {
      return null;
    }
    const allProgramsArray: Program[] = Object.values(this.allPrograms);
    const theProgram = allProgramsArray.find(program => program.programCode === programCode.toUpperCase());
    if (theProgram && this.isUndergrad(theProgram)) {
      return ELevelCategory.Undergrad;
    } else if (theProgram && (this.isGraduate(theProgram) || this.isGraduateOrDoctorateAndRequiresMasters(theProgram) || this.isExecutiveDoctoral(theProgram) || this.isPHD(theProgram))) {
      return ELevelCategory.Graduate;
    } else {
      return ELevelCategory.Undergrad;
    }
  }

  public isUndergrad(program: Program): boolean {
    return ['DL', 'DT', 'DA', 'LB', 'CU', 'RU', 'RR', 'CR'].some((wapp) => program.wappCode === wapp);
  }

  public isGraduate(program: Program): boolean {
    return ['GR', 'RG', 'CG', 'CD'].some((el) => program.wappCode === el);
  }

  public isGraduateOrDoctorateAndRequiresMasters(program: Program): boolean {
    const doctoralWapps = ['DR', 'RD', 'CL'];
    const graduateWapps = ['CM'];
    return [...doctoralWapps, ...graduateWapps].some((el) => program.wappCode === el) || program.levelCode === 'JD';
  }

  public isExecutiveDoctoral(program: Program): boolean {
    return program.wappCode === 'DO' || program.wappCode === 'RO';
  }

  public isPHD(program: Program): boolean {
    return program.wappCode === 'DC' || program.wappCode === 'RC';
  }

  public requiresMasterAndBachelor(program: Program): boolean {
    return ['DB', 'RB', 'DH', 'RH'].some((el) => program.wappCode === el);
  }

  public requiresMasterOrBachelor(program: Program): boolean {
    return ['DO', 'RO'].some((el) => program.wappCode === el);
  }

  checkIfTransferAndUpdateWapp(program: Program, providedColleges: College[] = []): string {

    const hasAttendedLiberty = providedColleges.some(college => college.degree
      && college.ceebCode === this.LIBERTY_CEEB);
    const isTransferStudent = providedColleges.some(college => college.degree);
    const isOnline = program.campCode === 'D';
    if (hasAttendedLiberty) return isOnline ? this.ONLINE_READMIT_WAPP : this.RESIDENT_READMIT_WAPP; // for students re-admitted to liberty
    if (isTransferStudent) return isOnline ? this.ONLINE_TRANSFER_WAPP : this.RESIDENT_TRANSFER_WAPP; // for transfer students

    return program.wappCode;
  }

  public getCurrentProgram(): Program {
    return this.currentProgram;
  }

  loadNestedPrograms(){
    if (this.isNestedProgramsLoaded) { return; }

    this.http.get<string>(`/rest/nestedprograms`)
      .pipe(timeout(8000), take(1))
      .subscribe({
        next: (nestedProgramsResponse) => {
          this.resolveProgramsEndpoint(nestedProgramsResponse);
          this.isNestedProgramsLoaded = true;
        },
        error: () => {
          this.http.get('assets/cache/nestedPrograms.json')
            .pipe(retry(1))
            .subscribe({
              next: (nestedProgramsResponse: string) => {
                this.resolveProgramsEndpoint(nestedProgramsResponse);
                this.isNestedProgramsLoaded = true;
              },
              error: () => {
                console.error('Failed to load the backup nestedPrograms.json file.' );
              }
            });
        }
      });
  }

  loadAllPrograms() {
    if (this.allProgramsLoaded.getValue()) { return; }

    this.http.get<Program[]>(`/rest/programs`)
      .pipe(timeout(8000))
      .subscribe({
        next: (response) => {
          this.allPrograms = response;
          this.prepareAllProgramsByCampusLevel();
          this.allProgramsLoaded.next(true);
        },
        error: () => {
          this.http.get('assets/cache/programs.json')
            .pipe(retry(1))
            .subscribe({
              next: (programs: Program[]) => {
                Object.assign(this.allPrograms, programs);
                this.prepareAllProgramsByCampusLevel();
                this.allProgramsLoaded.next(true);
              },
              error: () => {
                  console.error('Failed to load the backup programs.json file.' );
              }
            });
        }
      });
  }

  checkForRequiredProps(program: Program): Program {

    const campCode = program.campCode;
    const level = program.level;

    // check for wapp code
    // if all of the above are false then there is a problem
    if ([this.isUndergrad(program), this.isGraduate(program), this.isGraduateOrDoctorateAndRequiresMasters(program), this.isExecutiveDoctoral(program), this.isPHD(program), this.requiresMasterAndBachelor(program), this.requiresMasterOrBachelor(program)].every(value => value === false)) {
      // just set it to undergrad, graduate, or doctorate based on what we know about the program

      // also send an email
      const emailHeader = new EmailMessage(EmailMessage.NO_REPLY, EmailMessage.SAT, EmailMessage.NO_REPLY, `Invalid wapp code for ProgramCode: ${program.programCode}`, `Program came in with an invalid wapp code. ProgramCode: ${program.programCode}, with wapp code: ${program.wappCode}`, EmailType.API_ERROR);
      this.emailService.sendEmail(emailHeader).subscribe();

      switch (level + campCode) {
        case 'Undergraduate' + 'D':
          program.wappCode = 'DL';
          break;

        case 'Graduate' + 'D':
          program.wappCode = 'RG';
          break;

        case 'Doctorate' + 'D':
          program.wappCode = 'DR';
          break;

        case 'Undergraduate' + 'R':
          program.wappCode = 'RU';
          break;

        case 'Graduate' + 'R':
          program.wappCode = 'RG';
          break;

        case 'Doctorate' + 'R':
          program.wappCode = 'RD';
          break;

        default:
          program.wappCode = 'RU';
          break;
      }


    }

    return program;
  }

  public getCrosswalkProgram = (programCode: string) => new Promise<Program>(resolve => {
    this.http.get<Program>(`/rest/programs/${programCode}/crosswalk`, {responseType: 'json'})
      .pipe(
        switchMap((disclosure: Program) => {
          return of(disclosure).toPromise();
        }),
      ).toPromise().then(res => {
      resolve(res);
    });
  })

  private resolveProgramsEndpoint(nestedProgramsResponse: string) {
    // sometimes returns from the API as an object depending on environment and we dont know why
    const response = typeof nestedProgramsResponse !== 'object' ? JSON.parse(nestedProgramsResponse) : nestedProgramsResponse;
    Object.assign(this.pickerPrograms, response);
    this.programsLoaded.next(true);
  }
}
