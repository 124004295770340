import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Acode } from '../model/applyLUApiObjects/acode.model';


@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  DEFAULT_PAYMENT_AMOUNT = 250;
  paymentAmountSubject = new BehaviorSubject<number>(this.DEFAULT_PAYMENT_AMOUNT);
  constructor() {}

  public emitPaymentFromAcodes(acodes: Acode[]) {
    if (acodes.length === 0) {
      this.paymentAmountSubject.next(this.DEFAULT_PAYMENT_AMOUNT)
      return;
    }

    this.paymentAmountSubject.next(Math.min(...acodes.map(code => +code.deposit_discount_amt))) // emit lowest value
  }

}
