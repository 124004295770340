import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EmailMessage, EmailType} from '../model/email-message.model';
import {AuthenticationService} from './authentication.service';
import {Program} from '../model/program.model';
import {ApplicationDocument} from '../model/application-document';
import {forkJoin} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
  ) { }

  private static groupDocumentsBySize(files: ApplicationDocument[]) {
    files.sort((a, b) => b.file.size - a.file.size);
    const requests: ApplicationDocument[][] = [];
    let currentRequest: ApplicationDocument[] = [];
    let currentRequestSize = 0;
    for (let i = 0; i < files.length; i++) {
      const document = files[i];
      const maxRequestSize = 25000000;
      if (currentRequestSize + document.file.size > maxRequestSize) {
        requests.push(currentRequest);
        currentRequest = [document];
        currentRequestSize = document.file.size;
        continue;
      }
      currentRequest.push(document);
      currentRequestSize += document.file.size;
    }
    if (currentRequest.length > 0) {
      requests.push(currentRequest);
    }
    return requests;
  }


  sendEmail(emailMessage: EmailMessage) {
    return this.http.post('/rest/smtp', emailMessage, {responseType: 'text'});
  }

  sendErrorEmail(subject: string, body: string) {
    const emailMessage = new EmailMessage(EmailMessage.NO_REPLY, EmailMessage.SAT, '', subject, body, EmailType.API_ERROR);

    this.sendEmail(emailMessage).subscribe();
  }

  sendAttachmentToEtrieve(webId: string, files: ApplicationDocument[], program: Program, emailMessage: EmailMessage, luid?: string) {
    if (luid && luid.length === 9 && luid[0] === 'L') {
      emailMessage.messageBody += '\nLUID: ' + luid;
    } else if (this.authService.studentLUID.length === 9) {
      luid = this.authService.studentLUID;
      emailMessage.messageBody += '\nLUID: ' + luid;
    } else {
      luid = null;
    }

    if (program.level === "Doctorate") {
      // etrieve can only route documents to either Undergraduate or Graduate level (ALU-6567)
      program.level = "Graduate"
    }

    const documents = EmailService.groupDocumentsBySize(files);
    const requestObservables = documents.map(document => {
      const formData = new FormData();
      if (luid) {
        formData.append('luid', luid);
      }
      formData.append('webId', webId);
      formData.append('campus', program.campCode);
      formData.append('level', program.level);
      formData.append('types', new Blob([JSON.stringify(document.map(request => request.etrieveFileType))], { type: 'application/json'}));
      formData.append('emailMessage', new Blob([JSON.stringify(emailMessage)], { type: 'application/json'}));
      document.forEach(request => formData.append('files', request.file, request.file.name));
      return this.http.post('/rest/etrieve/attachment', formData);
    });
    return forkJoin(requestObservables);
  }

  sendEmailWithAttachment(files: ApplicationDocument[], emailMessage: EmailMessage) {
    const documents = EmailService.groupDocumentsBySize(files);
    const requestObservables = documents.map(document => {
      const formData = new FormData();
      formData.append('emailMessage', new Blob([JSON.stringify(emailMessage)], { type: 'application/json'}));
      document.forEach(request => formData.append('files', request.file, request.file.name));
      return this.http.post('/rest/smtp/attachment', formData);
    });
    return forkJoin(requestObservables);
  }
}
