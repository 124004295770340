import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { SchoolType } from 'src/app/form/input/generic/school-search/school-search-fieldset/school-search-fieldset.component';
import { FormControlCustom } from '../form-control-custom';
import { emailPattern, maxiumumBirthYear, minimumBirthYear, ssnPattern } from '../service/form.service';

export enum EFormType {
  student,
  agent
}

export interface SectionStatus {
  valid: boolean;
  disabled: boolean;
  visited: boolean;
  active: boolean;
  locked: boolean;
  visible: boolean;
}

export const yearPattern = /^(?=\d{4}$)(1|2)\d+/;

export const gradYearMax = new Date().getFullYear() + 5;
export const gradYearMin = gradYearMax - 105;


export class AppFormBuilder {

  public static generatePrevEdGroup(): FormGroup {
    return new FormGroup({
      sectionStatus: new FormControl<SectionStatus>(new AppFormBuilder().generateDefaultStatus()),

      previously_attended_college: new FormControlCustom<string>(
        { required: 'Indicate if you have attended Colleges, Universities or Dual Enrollment previously' },
        { value: '', disabled: true },
        [Validators.required]
      ),

      certify_degree_radio: new FormControlCustom<string>({ required: 'You must indicate if you will receive a diploma prior to enrolling' }, { value: '', disabled: true }, [Validators.required]),

      assert_gpa: new FormControlCustom<string>({ required: 'You must certify your GPA' }, { value: '', disabled: true }, [Validators.required]),

      hs_diploma_type: new FormControlCustom<string>({ required: 'Select a diploma type' }, { value: '', disabled: true }, [Validators.required]),

      hs_diploma_firstName: new FormControlCustom<string>({ required: 'Enter your first name as it appears on your diploma' }, { value: '', disabled: true }, [Validators.required]),

      hs_diploma_lastName: new FormControlCustom<string>({ required: 'Enter your last name as it appears on your diploma' }, { value: '', disabled: true }, [Validators.required]),

      ged_fields: new FormGroup({
        ged_diploma_month: new FormControlCustom<string>({ required: 'Select a graduation month' }, { value: '', disabled: true }, [Validators.required]),
        ged_diploma_year: new FormControlCustom<string>({ required: 'A year is required', min: `Enter a valid graduation year after ${gradYearMin - 1}`, max: `Enter a valid graduation year before ${gradYearMax + 1}` }, { value: '', disabled: true }, [Validators.required, Validators.min(gradYearMin), Validators.max(gradYearMax)]),
        ged_state: new FormControlCustom<string>({ required: 'Select your G.E.D. award state' }, { value: '', disabled: true }, [Validators.required]),
        ged_ceeb_code: new FormControl<string>({ value: '', disabled: true }),
        ged_hs_name: new FormControl<string>({ value: '', disabled: true })
      }),

      expelled_group: AppFormBuilder.generateExpelledGroup(),

      previous_balance: new FormControlCustom<string>({ required: 'Indicate if you have a previous balance' }, { value: '', disabled: true }, [Validators.required]),

      bachelorsSearch: new FormGroup({
        selected_schools: new FormArray([])
      }),

      mastersSearch: new FormGroup({
        selected_schools: new FormArray([])
      }),

      doctoralSearch: new FormGroup({
        selected_schools: new FormArray([])
      }),

      highschoolSearch: new FormGroup({
        selected_schools: new FormArray([])
      }),

      multiCollegeSearch: new FormGroup({
        selected_schools: new FormArray([]),
        done_entering_multi_school: new FormGroup({
          checkbox_values: new FormGroup({
            done_entering_multi_school: new FormControlCustom<string>(
              {
                required: 'Indicate that you have finished entering all schools attended',
                pattern: 'You must indicate that you have finished entering all schools attended'
              },
              { value: false, disabled: true }, Validators.requiredTrue)
          })
        })
      })

    });
  }

  public static generateExpelledGroup(): FormGroup {
    return new FormGroup({
      expelled_radio_question: new FormControlCustom<string>({ required: 'You must indicate if you were expelled or dismissed from your last Educational Institution' }, { value: '', disabled: true }, [Validators.required]),
      school_search: new FormControlCustom<string>({ required: 'You must provide a school you were expelled from' }, { value: '', disabled: true }),
      expelled_school: new FormControlCustom<string>({}, { value: '', disabled: true })
    });
  }

  public static generateSchoolFields(defaultLevel = '', schoolType: SchoolType, generateDidGraduate = false): FormGroup {
    let schoolSearchName = 'school_search';

    if (schoolType === SchoolType.Highschool) {
      schoolSearchName = 'highschool_school_search';
    } else if (schoolType === SchoolType.College) {
      schoolSearchName = 'college_school_search';
    }

    const generateGroup = new FormGroup({
      selected_school: new FormControl<string>(''),
      last_month_attended: new FormControlCustom<string>({ required: 'Select the last month attended' }, '', [Validators.required] ),
      grad_year: new FormControlCustom<string>({ required: 'A year is required', min: `Enter a valid year after ${gradYearMin - 1}`, max: `Enter a valid year before ${gradYearMax + 1}` }, '', [Validators.required, Validators.min(gradYearMin), Validators.max(gradYearMax)] ),
      degree_level_received: new FormControlCustom<string>({ required: 'Select what degree level you received' }, { value: defaultLevel, disabled: true }, [Validators.required]),
      city: new FormControlCustom<string>({ required: 'A school city is required' }, { value: '', disabled: true }),
      state_search_input: new FormControlCustom<string>({ required: 'Select a state' }, { value: '', disabled: true }),
      school_nation: new FormControlCustom<string>({ required: 'Select a nation' }, { value: 'United States', disabled: true }),
      [schoolSearchName]: new FormControlCustom<string>({ required: 'A school is required' }, '', [Validators.required])
    });

    if (generateDidGraduate) {
      generateGroup.addControl('did_graduate_radio', new FormControlCustom<string>({ required: 'Indicate if you graduated from this institution' }, '', [Validators.required]));
    }

    return generateGroup;
  }

  public static generatePersonalInfoFields(): FormGroup {
    return new FormGroup({
      sectionStatus: new FormControl<SectionStatus>(new AppFormBuilder().generateDefaultStatus()),

      email: new FormControlCustom<string>({ required: 'Provide an email', pattern: 'Provide a valid email' }, '', { validators: [Validators.pattern(emailPattern)], updateOn: 'blur' }),

      phone_fields: new FormGroup({
        phoneNumberInput: new FormControlCustom<string>({ required: 'Enter a phone number', pattern: 'Provide a valid phone number' }, '', [Validators.required] ),
        completePhone: new FormGroup({
          area: new FormControl<string>(''),
          phoneNumber: new FormControl<string>(''),
          type: new FormControl<string>(''),
          country: new FormControl<string>(''),
        }),
        isPossible: new FormControl<string>(''),
        isInternational: new FormControl<string>(''),
        cellPhoneRadio: new FormControl<string>('Yes'),
      }),

      name_fields: new FormGroup({
        firstName: new FormControlCustom<string>({ required: 'Enter your first name' }, '', [Validators.required] ),
        middleName: new FormControl<string>(''),
        lastName: new FormControlCustom<string>({ required: 'Enter your last name' }, '',  [Validators.required] ),
        lastNameChange: new FormControl<string>('No'),
        multiLastName: new FormControl<string>(''),
        suffix: new FormControl<string>('')
      }),

      address_fields: new FormGroup({
        street_address: new FormControlCustom<string>({ required: 'Provide a street address' }, '', [Validators.required]),
        city: new FormControlCustom<string>({ required: 'Enter a city' }, '', [Validators.required]),
        'shipping-state': new FormControl<string>(''),
        personal_info_state: new FormControlCustom<string>({ required: 'Select a state or province' }, '', [Validators.required]),
        zip_code: new FormControlCustom<string>({ required: 'Provide a zip code' }, ''),
        personal_info_country: new FormControlCustom<string>({ required: 'Select a country' }, 'United States'),
        personal_info_country_code: new FormControl<string>('US'),
        international_state: new FormControlCustom<string>({ required: 'Provide a state or province' }, { value: '', disabled: true }),
        international_disclosure: new FormControlCustom<string>({ required: 'We are sorry. Because of your country of residence and chosen program, you are unable to submit at this time' }, ''),
        us_resident_radio: new FormControl<string>('Yes'),
        usedGooglePlacesAPI: new FormControl<string>('No')
      }),

      state_disclosure_group: new FormGroup({
        state_disclosure: new FormControlCustom<string>({ required: 'We are sorry. Because of your state of residence and chosen program, you are unable to submit an application for this program.' }, ''),
        state_disclosure_radio: new FormControlCustom<string>({ required: 'Indicate if you agree to the state restrictions', pattern: 'Based on your state of residence and chosen program, you must agree to this statement before continuing.' }, '')
      }),

      relative_info: new FormGroup({
        email: new FormControlCustom<string>({ required: 'Provide an email', pattern: 'Provide a valid email' }, '', { validators: [Validators.pattern(emailPattern)], updateOn: 'blur' }),
        first_name: new FormControl<string>(''),
        last_name: new FormControl<string>(''),
        guardian_relationship: new FormControl<string>('')
      }),

      genderRadio: new FormControlCustom<string>({ required: 'Select a gender' }, '', [Validators.required]),

      birth_date: new FormGroup({
        birth_day: new FormControlCustom<string>({ required: 'Enter a birth day', pattern: 'Enter a valid birth day' }, '', [Validators.required, Validators.pattern(/^(0?[1-9]|[12]\d|3[01])$/)] ),
        birth_month: new FormControlCustom<string>({ required: 'Select a birth month' }, '', [Validators.required] ),
        birth_year: new FormControlCustom<string>({ required: 'Enter a birth year', pattern: 'Enter a valid birth year', max: 'Birthdate cannot be more than 100 years ago', min: 'Birthdate cannot be in the future' }, '', { validators: [Validators.required, Validators.min(minimumBirthYear), Validators.max(maxiumumBirthYear)], updateOn: 'blur' }),
        birth_date_is_valid: new FormControlCustom<string>({ pattern: 'Birth date cannot be today or later' }, 'Yes', { validators: [Validators.pattern('Yes')], updateOn: 'change' })
      }),

      maritalStatus: new FormControlCustom<string>({ required: 'Select your Marital Status' }, { value: '', disabled: true }),

      religion_search: new FormGroup({
        religion: new FormControl<string>({ value: '', disabled: true }),
        religion_code: new FormControl<string>('')
      }),

      race_select: new FormControl<string>(''),

      citizenshipGroup: new FormGroup({
        us_citizen_radio: new FormControlCustom<string>({ required: 'Provide your US citizenship status' }, '', [Validators.required]),
        country_of_birth_search: new FormControlCustom<string>({ required: 'Enter your Country of Birth' }, { value: '', disabled: true }, [Validators.required]),
        nation_search: new FormControlCustom<string>({ required: 'Select a nation' }, { value: '', disabled: true }, [Validators.required]),
        green_card_radio: new FormControlCustom<string>({ required: 'Provide your Legal Permanent Resident status' }, { value: '', disabled: true }, [Validators.required]),
        city_of_birth: new FormControlCustom<string>({ required: 'Enter a city of birth' }, { value: '', disabled: true }),
        citizenship: new FormControl<string>({ value: '', disabled: true }),
        nation_search_code: new FormControl<string>({ value: '', disabled: true }),
        country_of_birth_search_code: new FormControl<string>({ value: '', disabled: true }),
        international_matd_disclosure_checkbox: new FormGroup({}),
        visa_search: new FormControlCustom<string>({ required: 'You must select a visa status' }, { value: '', disabled: true }),
        visa_search_code: new FormControl<string>({ value: '', disabled: true })
      }),

      financial_aid: new FormControl<string>('No'),

      ssn: new FormControlCustom<string>({ pattern: 'Provide a valid SSN' }, '', { validators: [Validators.pattern(ssnPattern)] }),

      military: new FormGroup({
        active_military: new FormControl<string>('No'),
        veterans_affairs_benefits: new FormControlCustom<string>({ required: 'Indicate your Department of Veterans Affairs educational benefits, or Dependents Education Assistance eligibility' }, { value: '', disabled: true }),
        military_tuition: new FormControlCustom<string>({ required: 'Indicate if you are eligible to receive Military Tuition Assistance' }, { value: '', disabled: true }),
        military_paygrade: new FormControl<string>({ value: '', disabled: true }),
        military_status: new FormControlCustom<string>({ required: 'Indicate military status' }, { value: '', disabled: true }, [Validators.required]),
        military_branch: new FormControlCustom<string>({ required: 'Select military branch' }, { value: '', disabled: true }, [Validators.required])
      }),

      college_credits_radio: new FormControl<string>({ value: 'No', disabled: true }),

      college_certs_group: new FormGroup({
        college_certs_select: new FormGroup({
          certs: new FormControlCustom<string>({}, { value: '', disabled: true })
        })
      })
    });
  }

  public generateDefaultStatus(isVisible = true, isFirstSection = false): SectionStatus {
    return isFirstSection ? {
      valid: false,
      disabled: false,
      active: true,
      visited: true,
      locked: false,
      visible: isVisible
    } : {
      valid: false,
      disabled: true,
      active: false,
      visited: false,
      locked: true,
      visible: isVisible
    };
  }


  public buildForm(type: EFormType): FormGroup {

    // we are adding formGroups separately only because we conditionally add agentQuestions first and I'm not sure how else to conditionally add groups while ensuring they are first in line
    const returnedGroup = new FormGroup({});

    if (type === EFormType.agent) {
      returnedGroup.addControl(
        'agentQuestions', new FormGroup({
          sectionStatus: new FormControl<SectionStatus>(this.generateDefaultStatus(undefined, true)),
          agent_acode_search: new FormGroup({}),
          applicant_goals_group: new FormGroup({
            goals_search: new FormControlCustom<string>({ required: 'Select at least one goal'}, '', [Validators.required])

          }),

        })
      );
    }

    returnedGroup.addControl(
      'yourDegree', new FormGroup({
        sectionStatus: new FormControl<SectionStatus>(this.generateDefaultStatus(undefined, type !== EFormType.agent)),
        custom_program_questions: new FormGroup({}),
        program_type: new FormControl<string>(''),
        level_code: new FormControl<string>(''),
        level: new FormControl<string>(''),
        program: new FormControlCustom<string>({ required: 'Choose a program' }, '', { validators: [Validators.required] }),
        wappCode: new FormControl<string>(''),
        start_term: new FormControlCustom<string>({ required: 'Select your desired start term' }, '', { validators: [Validators.required] }),
        programSearchString: new FormControl<string>('')

      })
    );

    returnedGroup.addControl(
      'previousEducation', AppFormBuilder.generatePrevEdGroup()
    );

    returnedGroup.addControl(
      'personalInformation', AppFormBuilder.generatePersonalInfoFields()
    );

    const bDateValidControl = returnedGroup.get('personalInformation').get('birth_date').get('birth_date_is_valid');
    bDateValidControl.markAsTouched();
    bDateValidControl.markAsDirty();

    returnedGroup.addControl(
      'agreeSubmit', new FormGroup({
        sectionStatus: new FormControl<SectionStatus>(this.generateDefaultStatus()),

        tcpa_question: new FormGroup({}),

        expelled_group: AppFormBuilder.generateExpelledGroup(),

        hear_about_us_select: new FormGroup({}),



      })
    );

    returnedGroup.addControl(
      'data_fields', new FormGroup({}),
    );


    if (type === EFormType.student) {

      returnedGroup.addControl(
        'optional', new FormGroup({
          sectionStatus: new FormControl<SectionStatus>(this.generateDefaultStatus(false)),
          optional_essay_questions: new FormGroup({}),
        })
      );



    } else if (type === EFormType.agent) {

      returnedGroup.addControl(
        'enrollmentDeposit', new FormGroup({
          enrollment_deposit_question: new FormGroup({}),
          refund_terms_question: new FormGroup({}),
        })
      );

    }

    return returnedGroup;
  }

}
