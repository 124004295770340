import { Injectable } from '@angular/core';
import { Nation } from '../model/nation.model';
import { of, Subject } from 'rxjs';
import {FormService} from './form.service';
import {Nations} from '../model/nations.model';
@Injectable({
  providedIn: 'root'
})
export class NationService {

  private static readonly NATIONS_BASE_URL = '/rest/nations';

  nationsSubject = new Subject<Array<Nation>>();

  euNations = [
    'AT',
    'BE',
    'BG',
    'HR',
    'CY',
    'CZ',
    'DK',
    'EE',
    'FI',
    'FR',
    'DE',
    'GR',
    'HU',
    'IE',
    'IT',
    'LV',
    'LT',
    'LU',
    'MT',
    'NL',
    'PL',
    'PT',
    'RO',
    'SK',
    'SI',
    'ES',
    'SE',
  ];

  public _nations = new Array<Nation>();

  constructor(
    public formService: FormService
  ) { }


  public generateNations() {
      this._nations = Nations.sort((a, b) => a.name.localeCompare(b.name))
      this.nationsSubject.next(this._nations);
  }

  public findByCode(code: string): Nation {
    return this.nations.find(nation => nation.code === code) || null;
  }

  public findByName(name: string): Nation {
    return this.nations.find(nation => nation.name === name) || null;
  }

  private get nations(): Array<Nation> {
    return this._nations;
  }

  public returnNations(): Array<Nation> {
    return this._nations;
  }

  public isEU(): boolean {
    return this.euNations.includes(this.formService.currentNationCode);

  }

}
