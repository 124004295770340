import {AfterViewInit, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import { environment } from 'src/environments/environment';
import { Application } from '../shared/model/application.model';
import { GenerateLuidResponse } from '../shared/model/applyLUApiObjects/generate-luid-response.model';
import { IncompleteApp } from '../shared/model/applyLUApiObjects/incomplete-app.model';
import { EProgLevel } from '../shared/model/prog-level.model';
import { Program } from '../shared/model/program.model';
import { AppIdService } from '../shared/provider/app-id.service';
import { ApplicationService } from '../shared/service/application.service';
import { CookieService } from '../shared/service/cookie.service';
import { FormService } from '../shared/service/form.service';
import { IncompleteAppService } from '../shared/service/incomplete-app.service';
import { NavigationService } from '../shared/service/navigation.service';
import { ProgramService } from '../shared/service/program.service';
import { RouterService } from '../shared/service/router.service';
import {ActivatedRoute} from '@angular/router';
import { EmailMessage } from '../shared/model/email-message.model';
import { AnalyticsService } from '../shared/service/analytics.service';
import { DomService } from '../shared/service/dom.service';
import {EIconType} from '../shared/component/icon/icon.component';
import {PaymentService} from '../shared/service/payment.service';


@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss']
})

export class ThankYouComponent implements OnInit, AfterViewInit {

  application: Application;
  programCode: string;
  programUgOrGr: string;
  tyProgram: Program;
  studentEmail = 'your Email';
  emailToAddress: string = EmailMessage.SAT;

  finishedApplication = false;
  showEssaylUpload = false;
  showUnofficialTranscripts = false;
  showIdUpload = false;

  isUndergrad = false;
  isGrad = false;
  isRes = false;
  programLevel: EProgLevel;

  hasAgentSection = false;
  isInternational = false;
  isActiveMilitary = false;
  hasMilitaryError = false;
  url = environment.url;

  isEnrollmentDepositSelected: boolean;
  isRefundTermsAccepted: boolean;
  appIdIsCopied = false;

  stepTwoContent = `Check <b>${this.studentEmail}</b> for information on how to claim your Liberty account.`;
  hasMilitaryCode = false;

  studentForm = null;

  constructor(
    public applicationService: ApplicationService,
    public programService: ProgramService,
    public formService: FormService,
    private navigationService: NavigationService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private domService: DomService,
    private appIdService: AppIdService,
    private paymentService: PaymentService
  ) {}

  ngOnInit(): void {

    environment.isThankYouPage = true;
    this.studentForm = this.formService.mainFormData;

    if (this.studentForm.data_fields?.shouldDisplayIdUpload === true) {
      this.showIdUpload = true;
    }

    this.hasAgentSection = this.navigationService.possibleSections[0].navNumber === 0;

    if (this.applicationService.getAnswer('239').length > 0 && !this.hasAgentSection) {
      this.navigationService.possibleSections.unshift({ name: 'agentQuestions', text: 'Agent Questions', nameAttribute: 'Agent Questions', mobileText: 'Agent', locked: false, visited: false, valid: false, active: true, disabled: false, navNumber: 0 });
      environment.isAgent = true;
      this.hasAgentSection = true
    }

    this.application = this.applicationService.application;
    this.programCode = this.application.majorFoses[0] ? this.application.majorFoses[0].programCode : '';
    this.tyProgram = this.programService.getProgramByProgramCode(this.programCode);
    this.programUgOrGr = this.programService.getLevelCategoryByProgramCode(this.programCode);


    if (this.application.emails[0]) {
      this.studentEmail = this.application.emails[0].emailAddress;
      this.stepTwoContent = `Check <b>${this.studentEmail}</b> for information on how to claim your Liberty account.`;
    }

    this.isProgramUndergrad();
    this.isProgramGrad();
    this.isProgramRes();
    this.checkIsInternational();
    this.setProgramLevel();

    // Check for military code on init. If we have one, set that property so that we show the verified html bullet.
    this.isActiveMilitary = this.applicationService.getAnswer('308') === 'Yes';
    const militaryCode: string = (this.route.snapshot?.queryParams['code']);
    const redirectUrl = `${this.url}/thank-you`;
    const appId = this.cookieService.getCookie(this.cookieService.cookieEnum.APP_ID);
    this.hasMilitaryCode = militaryCode?.length > 0;

    // If they are military and do NOT have the idMe_verified cookie, send the id-me verify request
    const idMeCookie = this.cookieService?.getCookie('idMe_verified');
    if (this.hasMilitaryCode && this.isActiveMilitary && idMeCookie !== appId) {
    // First, add the cookie before the response comes back. It's possible to refresh before the response comes back and send a duplicate request
      this.cookieService.addCookie('idMe_verified', appId);
      this.applicationService.getMilitaryVerification(appId, militaryCode, redirectUrl).subscribe({
        error: () => this.hasMilitaryError = true,
      });
    }

    this.finishedApplication = this.studentEmail !== 'your Email';

    if (
      this.tyProgram
      && this.programLevel === 'Bachelor'
      && this.isRes === true
      && this.applicationService.getAnswer('247').length < 1
      && this.applicationService.getAnswer('248').length < 1
      && this.finishedApplication
      && this.tyProgram && this.tyProgram.programCode && this.tyProgram.programCode !== 'SPCU-BS-R' && this.tyProgram.programCode !== 'SPCG-MA-R'
      && !this.applicationService.festivalsApp
    ) {
      this.showEssaylUpload = true;
    }

    if (
      this.finishedApplication
      && !this.applicationService.spcApp
      && !this.applicationService.festivalsApp
      && (
        this.isRes
        || (!this.isRes && (this.programLevel === EProgLevel.Bachelor || this.programLevel === EProgLevel.Associate || this.programLevel === EProgLevel.Master))
      )
      && this.tyProgram && this.tyProgram.programCode && this.tyProgram.programCode !== 'SPCU-BS-R' && this.tyProgram.programCode !== 'SPCG-MA-R'
    ) {
      this.showUnofficialTranscripts = true;
    }

    // MultiFile: If production, send to either res or online admissions email
    this.emailToAddress = this.isRes ? EmailMessage.ADMISSION_DOCS_RES : EmailMessage.ADMISSION_DOCS_ONLINE;

    window.scrollTo(0, 0);
    this.checkStepTwoContent();
    this.cdr.detectChanges();

  }

  ngAfterViewInit() {
    this.setEnrollmentDepositAmount();
    this.domService.selectElementAsRoot('#thank-you-desc').focus();

    this.navigationService.possibleSections.forEach(menuItem => {
      menuItem.valid = true;
      menuItem.disabled = false;
      menuItem.locked = false;
      menuItem.active = false;
      menuItem.visited = true;
    });

    window.scrollTo(0, 0);
  }

  public checkStepTwoContent() {
    const luidResponse: GenerateLuidResponse = this.applicationService.generateLuidresponse;

    // luid generated and account claimed
    if (this.luidIsValid(luidResponse.luid) && this.usernameIsValid(luidResponse.username)) {
      this.stepTwoContent = `Check your email at <b>${this.studentEmail}</b> for next steps in the admissions process.`;

    // luid generated but account not claimed
    } else if (this.luidIsValid(luidResponse.luid)) {

        const claimLink = environment.production
          ? `https://idp.liberty.edu/sso/fgClaim`
          : `https://idp-test.liberty.edu/sso/fgClaim`;

        this.stepTwoContent = `<a href='${claimLink}' target='_blank'>Claim your account</a> at your earliest convenience.`;
      }
  }

  luidIsValid(luid: string): boolean {
    return luid && luid !== '' && luid !== 'SUSPENDED';
  }

  copyPaymentKey() {
    navigator.clipboard.writeText(this.appIdService.getAppId()).then( () => {
      this.appIdIsCopied = true;
    })
  }

  private setEnrollmentDepositAmount() {
    const depositAmount = this.studentForm.data_fields?.enrollmentDepositAmount;
    if (depositAmount) {
      this.paymentService.paymentAmountSubject.next(depositAmount);
    }
  }

  usernameIsValid(username: string): boolean {
    return !!username;
  }

  setProgramLevel() {
    if (this.tyProgram) {
      this.programLevel = this.programService.getLevelByProgramCode(this.tyProgram.programCode);
    }
  }

  isProgramUndergrad() {
    if (this.tyProgram) {
      this.isUndergrad = this.programService.getLevelByProgramCode(this.tyProgram.programCode) === 'Bachelor' || this.programService.getLevelByProgramCode(this.tyProgram.programCode) === 'Associate' || this.programService.getLevelByProgramCode(this.tyProgram.programCode) === 'Certificate';
    }
  }

  isProgramGrad() {
    if (this.tyProgram) {
      this.isGrad = this.programService.getLevelByProgramCode(this.tyProgram.programCode) === 'Doctorate' || this.programService.getLevelByProgramCode(this.tyProgram.programCode) === 'Master';
    }
  }

  isProgramRes() {
    if (this.tyProgram) {
      this.isRes = this.programService.getCampusByProgramCode(this.tyProgram.programCode) === 'R';
    }
  }

  checkIsInternational() {
    if (this.tyProgram) {
      this.isInternational = this.applicationService.getAnswer('310') === 'No';
    }
  }

  public onDepositAnswer(answer: string) {
    this.isEnrollmentDepositSelected = answer === 'Yes';
    if (!this.isEnrollmentDepositSelected) {
      this.isRefundTermsAccepted = false;
    }
  }

  public onRefundTermsAnswer(answer: boolean) {
    this.isRefundTermsAccepted = answer === true;
  }

  protected readonly environment = environment;
  protected readonly EIconType = EIconType;
}

export function thankYouInit(
  appIdService: AppIdService,
  applicationService: ApplicationService,
  cookieService: CookieService,
  incompleteAppService: IncompleteAppService,
  routerService: RouterService,
  programService: ProgramService,
  formService: FormService,
  analyticsService: AnalyticsService
) {
  return async (): Promise<any> => {
    analyticsService.initVwo();
    await analyticsService.initGooglePlaces();

    if (window.location.href.includes('UPAY_SITE_ID')) {
      routerService.navigate('/thank-you/.');
    }

    cookieService.sanitizeCookies();

    if (cookieService.checkCookie(cookieService.cookieEnum.APP_ID)) {

      if (programService.allPrograms.length === 0) {
        // need to request allPrograms if not present when refreshing ty screen to fill in some details
        programService.loadAllPrograms();
      }

      return incompleteAppService.getThankYouAppByResumeKey(cookieService.getCookie(cookieService.cookieEnum.APP_ID), cookieService.getCookie(cookieService.cookieEnum.RESUME_KEY))
        .toPromise()
        .then((incompleteApp: IncompleteApp) => {

          if (incompleteApp.id) {
            // set up luid response object if data is available
            applicationService.generateLuidresponse = new GenerateLuidResponse(incompleteApp.luid, incompleteApp.username, incompleteApp.pidm);

            appIdService.setAppId(incompleteApp.id.toString());

            // even if an IncompleteApp is found & returned, sometimes the appJson is null
            const parsedResponse = JSON.parse(incompleteApp.appJson);
            applicationService.application = new Application(parsedResponse);
            applicationService.webId = incompleteApp.webId;
            applicationService.submitSuccess = true;
            applicationService.submitted = true;

            formService.mainFormData = JSON.parse(incompleteApp.formObject);
          }

        });

    } else {
      return () => { applicationService.application = new Application(); };
    }
  };

}
