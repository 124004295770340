import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { AppIdService } from '../shared/provider/app-id.service';
import { SummaryComponent } from './summary-component/summary.component';
import { AppObjectToggleComponent } from './app-object-toggle/app-object-toggle.component';
import { EnvironmentWarningComponent } from './environment-warning/environment-warning.component';
import { InputModule } from './input/input.module';
import { AgentFormComponent } from './agent-form/agent-form.component';
import { StudentFormComponent } from './student-form/student-form.component';
import { LittlePopupComponent } from './little-popup/little-popup.component';
import { OptionalEssayQuestionsComponent } from './optional-essay-questions/optional-essay-questions.component';
import { FormComponent } from './form.component';
import { CitizenshipModule } from './input/citizenship/citizenship.module';
import { FullAddressModule } from './input/personal-info/full-address/full-address.module';
import { ContinueButtonComponent } from './continue-button/continue-button.component';
import { IconModule } from '../shared/component/icon/icon.module';
import { LoadingRingModule } from '../shared/component/loading-ring/loading-ring.module';
import { GenericInputModule } from './input/generic/generic-input.module';
import { FormErrorModule } from './form-error/form-error.module';
import { SectionsModule } from './sections/sections.module';
import {TreeModule} from '@ali-hm/angular-tree-component';
import {provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';

@NgModule({
  declarations: [
    AgentFormComponent,
    StudentFormComponent,
    SummaryComponent,
    AppObjectToggleComponent,
    EnvironmentWarningComponent,
    LittlePopupComponent,
    OptionalEssayQuestionsComponent,
    FormComponent,
    ContinueButtonComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    InputModule,
    GenericInputModule,
    CitizenshipModule,
    SectionsModule,
    FullAddressModule,
    IconModule,
    LoadingRingModule,
    FormErrorModule,
    TreeModule,
  ],
  providers: [
    AppIdService,
    provideHttpClient(withInterceptorsFromDi())
  ],
  exports: [
    AgentFormComponent,
    StudentFormComponent,
    SummaryComponent,
    AppObjectToggleComponent,
    EnvironmentWarningComponent,
  ]
})
export class FormModule { }
