import { Component, Input } from '@angular/core';

export enum EIconType {
  Bachelor = 'bachelor',
  Call = 'call',
  Chat = 'chat',
  Check = 'check',
  Copy = 'copy',
  Desk = 'desk',
  Doctorate = 'doctorate',
  DropdownArrow = 'dropdown-arrow',
  Edit = 'edit',
  EmptyCircle = 'empty-circle',
  Envelope = 'envelope',
  InfoIcon = 'info',
  Lightbulb = 'Lightbulb',
  Loading = 'Loading',
  LULogoLong = 'LU-logo-long',
  LuWordmark = 'lu-wordmark',
  Master = 'master',
  Megaphone = 'megaphone',
  OnCampus = 'on-campus',
  Padlock = 'padlock',
  Search = 'search',
  SideWhiteArrow = 'side-white-arrow',
  SparkyMobile = 'sparky-mobile',
  Sparky = 'Sparky',
  Tooltip = 'tooltip',
  WhiteSearch = 'white-search',
  x = 'x'
}

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styles: ['svg {max-width: 100%; max-height: 100%;}', '.whiteArrow {stroke: white}']
})
export class IconComponent {

  EIconType = EIconType;

  @Input() icon: string;

  constructor() { }

}
