import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {FormService} from 'src/app/shared/service/form.service';
import {AppIdService} from 'src/app/shared/provider/app-id.service';
import {CookieService} from '../../../../shared/service/cookie.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {PaymentService} from '../../../../shared/service/payment.service';

@Component({
  selector: 'app-data-fields',
  templateUrl: './data-fields.component.html',
  styleUrls: ['./data-fields.component.scss']
})
export class DataFieldsComponent implements OnInit, OnDestroy {

  progressNumber = this.formService.formProgress;

  @Input() formGroup: FormGroup<any> = new FormGroup({});

  endSubs = new Subject<void>();

  constructor(
    private appIdService: AppIdService,
    private formService: FormService,
    private cookieService: CookieService,
    private paymentService: PaymentService
  ) {
  }

  ngOnInit(): void {
    this.formGroup.addControl('appId', new FormControl<string>(''));
    this.formGroup.addControl('ipAddress', new FormControl<string>(''));
    this.formGroup.addControl('email', new FormControl<string>(''));
    this.formGroup.addControl('pidm', new FormControl<string>(''));
    this.formGroup.addControl('appProgress', new FormControl<number>(0));
    this.formGroup.addControl('allCookies', new FormControl<string>(this.cookieService.getAllCookies().toString()));
    this.formGroup.addControl('vwoCookies', new FormControl<string>(this.cookieService.getVWOCookies()));
    this.formGroup.addControl('enrollmentDepositAmount', new FormControl<number>(this.paymentService.DEFAULT_PAYMENT_AMOUNT));

    // for student login functionality
    this.formGroup.addControl('studentHasLoggedIn', new FormControl(false));
    this.formGroup.addControl('shouldDisplayIdUpload', new FormControl(false));


    this.formService.emitProgress.pipe(takeUntil(this.endSubs)).subscribe(progress => {
      this.progressNumber = progress;
      this.formGroup.get('appProgress').setValue(this.progressNumber, {emitEvent: false});
    });

    this.paymentService.paymentAmountSubject.pipe(takeUntil(this.endSubs)).subscribe(paymentAmount => {
      this.formGroup.get('enrollmentDepositAmount').setValue(paymentAmount);
    })

    this.appIdService.ipUpdated.pipe(takeUntil(this.endSubs)).subscribe((newIp: string) => {
      this.setIpAddress(newIp);
    });

    this.appIdService.getAppIdSetSub().pipe(takeUntil(this.endSubs)).subscribe(appID => {
      this.formGroup.get('appId').setValue(appID, {emitEvent: false});
    });

    this.formService.getShouldDisplayIdUploadSub().pipe(takeUntil(this.endSubs)).subscribe((should: boolean) => {
      this.formGroup.get('shouldDisplayIdUpload').setValue(should);
    });

    if (this.cookieService.getBadCookieBoolean()) {
      this.formGroup.addControl('badCookieFound', new FormControl<string>('Yes'));
    }
  }

  ngOnDestroy(): void {
    this.endSubs.next();
    this.endSubs.complete();
  }

  public setIpAddress(ip: string) {
    this.formGroup.get('ipAddress').setValue(ip, {emitEvent: false});
  }

}
