<div class="file-upload">
    <br>
    <app-alert [goFullWidth]="true">
        Answer each question in just 100-150 words:<br>
        1. Describe how your personal faith and beliefs contribute to your college search process.<br><br>
        2. Liberty’s mission is to develop Christ-centered leaders. Describe how you will contribute to this mission as a Liberty student.<br><br>
        The admissions essays can also be emailed to <a href="mailto:admissionsdocs@liberty.edu">admissionsdocs&#64;liberty.edu</a>
    </app-alert>

    <h3 *ngIf="sentToAPI" class="sent" role="alert">{{fileListLength}} Essay{{fileListLength === 1 ? ' has' : 's have'}} been sent to admissions</h3>

    <h3 *ngIf="failedSendToAPI" role="alert" class="failed-send">{{fileListLength}} Essay{{fileListLength === 1 ? '' : 's'}} unable to send to admissions.
        Email your Essay to {{toAddress}}.</h3>

    <div *ngIf="!sentToAPI && !failedSendToAPI">
        <br [ngClass]="{'hidden' : fileListLength > 0}">
        <app-file-upload [uploadType]="'Essay'" [approvedFileTypes]="approvedFileTypes" [approvedFileTypesName]="approvedFileTypesNames" [isEssayUpload]="true" (filesEmitted)="sendEssays($event)"></app-file-upload>
    </div>
</div>
