<div class="d-flex dt-flex--ReverseRow">

  <div class="thank-you-container">

    <app-decorative-card aria-describedby="thank-you-desc">
      <h1>Thank you{{ application.firstName ? ', ' + application.firstName : '' }}!</h1>
      <p class="text-green">You have successfully <span
        [innerHTML]="applicationService.spcApp ? 'submitted your non-degree<br>application': 'applied'"></span> to
        Liberty
        University!</p>
    </app-decorative-card>

    <div id="thank-you-desc" tabindex="0" class="screen-reader-only">Thank you{{ application.firstName ? ', '
      + application.firstName : '' }}. You have successfully <span
      [innerHTML]="applicationService.spcApp ? 'submitted your non-degree<br>application': 'applied'"></span> to Liberty
      University!
    </div>

    <h3 *ngIf="hasAgentSection && applicationService.webId" class="mt-5 d-block" id="webid">WebID:
      {{applicationService.webId}}</h3>

    <div class="mt-5 row" *ngIf="applicationService.festivalsApp">
      <div class="col">
        <a class="button" href="/festivals">Start a new application</a>
      </div>
    </div>

    <h3 class="mt-5" *ngIf="luidIsValid(applicationService.generateLuidresponse.luid)">Your Liberty University ID
      is: {{applicationService.generateLuidresponse.luid}}</h3>

    <ol>

    <app-enrollment-deposit-radio
      *ngIf="this.hasAgentSection && this.isRes && this.isUndergrad"
      (depositAnswer)="onDepositAnswer($event)">
    </app-enrollment-deposit-radio>

    <app-refund-terms-radio
      (refundTermsAnswer)="onRefundTermsAnswer($event)"
      *ngIf="this.hasAgentSection && this.isRes && this.isUndergrad && isEnrollmentDepositSelected">
    </app-refund-terms-radio>

    <button
      class="copy-button"
      *ngIf="isRefundTermsAccepted"
      type="button"
      (click)="copyPaymentKey()">

      <div class="icon-container">
        <app-icon *ngIf="!appIdIsCopied" class="copy-icon" icon="copy"></app-icon>
        <div *ngIf="appIdIsCopied" class="navMenuIcon">
          <app-icon class="menu-checkmark animated-checkmark" [icon]="EIconType.Check"></app-icon>
        </div>
      </div>

        <span *ngIf="!appIdIsCopied">Copy Payment Key</span>
        <span *ngIf="appIdIsCopied">Copied!</span>
      </button>

      <p *ngIf="appIdIsCopied">Complete the payment in Finesse to automatically apply the application fee waiver to the student's account.</p>

    <h2
      [ngClass]="{'mt-3': luidIsValid(applicationService.generateLuidresponse.luid), 'mt-5': !luidIsValid(applicationService.generateLuidresponse.luid)}">
      Here are your next steps</h2>

      <ng-container *ngIf="isInternational && isRes && !applicationService.festivalsApp">

        <li *ngIf="showUnofficialTranscripts">
          If you have any
          <app-tooltip [decoratedText]="'unofficial transcripts'">
            Unofficial transcripts are obtained by the student via their school account or have been opened by the
            student.
            Unofficial transcripts can also be emailed to
            <a *ngIf="isRes" href="mailto:admissionsdocs@liberty.edu">admissionsdocs&#64;liberty.edu</a>
            <a *ngIf="!isRes" href="mailto:luoverify@liberty.edu">luoverify&#64;liberty.edu</a>
          </app-tooltip>
          from a United States accredited school, you can streamline your admission process by uploading them here. Any
          secondary or college coursework completed outside of the U.S. will require a NACES credential evaluation.

          <app-transcript-upload
            [campus]="'Residential'"
            [replyToAddress]="studentEmail"
            [fullName]="application.firstName + ' ' + application.lastName"
            [toAddress]="emailToAddress"
            *ngIf="finishedApplication">
          </app-transcript-upload>
        </li>

        <li>You can expect an email from Liberty University to help guide you through the admission process.</li>

        <li [innerHTML]="stepTwoContent"></li>

        <li>Submit your admission documents to
          <a href="mailto:international@liberty.edu">international&#64;liberty.edu </a> for acceptance and visit <a
            href="https://www.liberty.edu/residential-at-liberty/?utm_source=Res_Adm&utm_medium=redirect&utm_campaign=undergrad_redirect&utm_content=redirect"
            target="_blank">International Admission Requirements </a> for more information.
        </li>

        <li *ngIf="isUndergrad">Check out our <a href="https://www.liberty.edu/residential/visit-us" target="_blank"
                                                 rel="noopener noreferrer nofollow">visit</a> opportunities such as
          College For A Weekend or ExperienceLU
        </li>

        <li *ngIf="showEssaylUpload">
          If you have written your admission essay, you can streamline your admission process by uploading them here.
          The admissions essays can also be emailed to <a href="mailto:admissionsdocs@liberty.edu">admissionsdocs&#64;liberty.edu</a>.

          <app-essay-upload *ngIf="showEssaylUpload"
                            [replyToAddress]="studentEmail" [fullName]="application.firstName + ' ' + application.lastName">
          </app-essay-upload>
        </li>

        <li *ngIf="showIdUpload">
          <app-multi-file-upload></app-multi-file-upload>
        </li>

      </ng-container>


      <ng-container *ngIf="!isInternational || (isInternational && !isRes)">

        <li>You can expect a phone call from Liberty University to help guide you through the admission process.</li>

        <li *ngIf="isActiveMilitary">
          <div
            [ngClass]="{'hidden' : hasMilitaryCode}"
            id="idme-verification">
            <div>You indicated on your application you have military affiliation. Please click the button below to
              verify your military-affiliated status to be considered for Liberty’s generous military discounts and
              benefits.
              <br>
              <br>
              If you are a spouse or child of a service member or veteran, to ensure timely review of your benefit
              eligibility, verify through ID.me using your credentials and not the credentials of the service member or
              veteran you are affiliated with. If you do not have ID.me credentials, you can create them via the ID.me
              button below before verifying your status.
              <p style="margin-left: 20px"><br>Note: If you are a current or prospective student who has previously
                applied to a Liberty University program and completed verification through the ID.me button below,
                please review the process on our <a href="https://www.liberty.edu/military/benefits" target="_blank">Military
                  Benefits Webpage</a> to confirm your next steps. Thank you.</p>
            </div>
            <a
              href="https://api.id.me/oauth/authorize?client_id=22b5c90f94c06ed85b368927c0118a28&redirect_uri={{url}}/thank-you&response_type=code&scope=military"
              target="_blank">
              <img id="idMeButton" src="https://s3.amazonaws.com/idme/developer/idme-buttons/assets/img/verify.svg"
                   height="50"/>
            </a>
          </div>

          <div [ngClass]="{'hidden': !hasMilitaryCode}">
            <p style="color: #0C8A3E; font-weight: bold">

              We have received your verification through ID.me. Please allow 3-5 business days for your account to be
              reviewed. You will receive additional information to your Liberty email account with any next steps or
              account updates.

              <span [ngClass]="{'hidden': !hasMilitaryCode  || !hasMilitaryError}"
                    style="font-weight: bold; color: black">
                Unfortunately, we had issues processing your information. Please check your email for next steps on how to finalize your verification.
              </span>

            </p>
          </div>

        </li>

        <li [innerHTML]="stepTwoContent"></li>

        <li *ngIf="isRes && isUndergrad">At Liberty University’s College For A Weekend (CFAW), you can spend three days
          test-driving life as an LU student – <a href="https://www.liberty.edu/residential/cfaw/" target="_blank"
                                                  >learn more</a> now.
          <ul class="sub-list">
            <li>Check out more <a href="https://www.liberty.edu/residential/visit-us/" target="_blank"
                                  >visiting opportunities,</a> such as ExperienceLU,
              TourLU and virtual visit opportunities.
            </li>
          </ul>
        </li>

        <ng-container *ngIf="!applicationService.spcApp && !applicationService.festivalsApp">
          <li *ngIf="isRes">You can always submit your admission documents to
            <a href="mailto:admissionsdocs@liberty.edu">admissionsdocs&#64;liberty.edu </a>or view a complete list of
            <a *ngIf="isUndergrad" href="https://www.liberty.edu/residential/undergraduate" target="_blank">admission
              requirements</a>
            <a *ngIf="isGrad" href="https://www.liberty.edu/residential/graduate" target="_blank">admission
              requirements</a>
          </li>

        </ng-container>

        <li *ngIf="!isRes">View <a href="https://www.liberty.edu/online/admission-requirements" target="_blank"
                                   rel="noopener noreferrer nofollow">Admission Requirements</a> for more information
        </li>

        <li *ngIf="showUnofficialTranscripts">
          If you have unofficial transcripts
          <app-tooltip>
            Unofficial transcripts are obtained by the student via their school account or have been opened by the
            student.
            Unofficial transcripts can also be emailed to
            <a *ngIf="isRes" href="mailto:admissionsdocs@liberty.edu">admissionsdocs&#64;liberty.edu</a>
            <a *ngIf="!isRes" href="mailto:luoverify@liberty.edu">luoverify&#64;liberty.edu</a>
          </app-tooltip>
          in your possession, you can streamline your admission process by uploading them here:

          <p class="red-transcript-instructions">If you have multiple transcripts, please attach each school separately.
            Please do not combine multiple schools into one attachment.</p>

          <app-transcript-upload
            [campus]="isRes ? 'Residential' : 'Online'"
            [replyToAddress]="studentEmail"
            [fullName]="application.firstName + ' ' + application.lastName"
            [toAddress]="emailToAddress"
            *ngIf="finishedApplication && !applicationService.spcApp && !applicationService.festivalsApp">
          </app-transcript-upload>
        </li>

        <li *ngIf="showEssaylUpload && !applicationService.spcApp && !applicationService.festivalsApp">
          If you have written your admission essay, you can streamline your admission process by uploading them here:

          <app-essay-upload *ngIf="showEssaylUpload && !applicationService.spcApp && !applicationService.festivalsApp"
                            [replyToAddress]="studentEmail" [fullName]="application.firstName + ' ' + application.lastName">
          </app-essay-upload>
        </li>

        <li *ngIf="showIdUpload">
          Please submit two forms of identification to support the personal information change you've requested.
          Acceptable documents include: Court Documents, Driver's License, Birth Certificate, Passport or Marriage
          Certificate.
          <app-multi-file-upload [sendToAddress]="emailToAddress"
                                 [subject]="'ID change upload for WebID: ' + applicationService.webId"
                                 [body]="'ID change upload: ' + application.firstName + ' ' + application.lastName + ', ' + applicationService.webId + '\nEmail: noreply@liberty.edu'"
                                 [fileName]="'Identification'"></app-multi-file-upload>
        </li>

      </ng-container>


    </ol>

    <app-referral-form *ngIf="isRes && !applicationService.spcApp"></app-referral-form>


    <div class="contact-us my-5">
      <h2>Still have questions?</h2>

      <div>Call us at
        <a *ngIf="(!isInternational && isUndergrad && isRes) || (applicationService.spcApp)" href="tel:800-543-5317">(800)
          543-5317</a>
        <a *ngIf="!isInternational && isGrad && isRes" href="tel:877-298-9617">877-298-9617</a>
        <a *ngIf="!isRes && !applicationService.spcApp" href="tel:800-424-9595">(800) 424-9595</a>
        <a *ngIf="isInternational && isRes" href="tel:877-298-9617">877-298-9617</a>
      </div>

      <div *ngIf="(!isInternational && isUndergrad && isRes) || (applicationService.spcApp)">Parent help line <a
        href="tel:855-745-3568">855-745-3568</a></div>

      <div *ngIf="(isRes && !isInternational) || (applicationService.spcApp)">Text us <a href="tel:49596">49596</a>
      </div>

      <div>Email us at
        <a *ngIf="!isInternational && isGrad && isRes"
           href="mailto:residentgraduate@liberty.edu">residentgraduate&#64;liberty.edu</a>
        <a *ngIf="(!isInternational && isUndergrad && isRes) || (applicationService.spcApp)"
           href="mailto:admissions@liberty.edu">admissions&#64;liberty.edu</a>
        <a *ngIf="!isRes && isUndergrad" href="mailto:luoundergrad@liberty.edu">luoundergrad&#64;liberty.edu</a>
        <a *ngIf="!isRes && isGrad" href="mailto:luograd@liberty.edu">luograd&#64;liberty.edu</a>
        <a *ngIf="isInternational && isRes" href="mailto:international@liberty.edu">international&#64;liberty.edu</a>
      </div>

      <div>Visit
        <a *ngIf="(!isInternational && isUndergrad && isRes) || (applicationService.spcApp)"
           href="https://www.liberty.edu/residential" target="_blank"
           rel="noopener noreferrer nofollow">www.liberty.edu/residential</a>
        <a *ngIf="!isInternational && isGrad && isRes" href="https://www.liberty.edu/residential/graduate"
           target="_blank"
           rel="noopener noreferrer nofollow">www.liberty.edu/residential/graduate</a>
        <a *ngIf="!isRes && !applicationService.spcApp" href="https://www.liberty.edu/online" target="_blank"
           rel="noopener noreferrer nofollow">www.liberty.edu/online</a>
        <a *ngIf="isInternational && isRes" href="http://www.liberty.edu/residential/international" target="_blank"
           rel="noopener noreferrer nofollow">www.liberty.edu/residential/international</a>
      </div>

    </div>

  </div>

</div>
