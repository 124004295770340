import {enableProdMode} from '@angular/core';
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import { init as initApm } from '@elastic/apm-rum';

if (environment.production) {
  enableProdMode();
}


if (environment.envType !== 'local') {
  initApm({
    // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
    serviceName: 'apply_lu',
    // Set custom APM Server URL (default: http://localhost:8200)
    serverUrl: 'https://apm.liberty.edu/',
    environment: environment.envType,
    distributedTracing: true,
    distributedTracingOrigins: [environment.api]
  });
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
